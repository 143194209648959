import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/bs/layout"
//import Image from "../components/image"
import SEO from "../components/bs/seo"

import MainImg from "../images/main_bg.jpg"

import shape1 from "../images/dec/1.png"
import shape2 from "../images/dec/2.png"
import shape3 from "../images/dec/3.png"
import shape4 from "../images/dec/4.png"
import shape5 from "../images/dec/5.png"
import shape6 from "../images/dec/6.png"
import shape7 from "../images/dec/7.png"
import shape8 from "../images/dec/8.png"

const Impresum = () => (
  <Layout>
    <SEO
      title="IMMMUNO NATURA – IMPRESUM"
      description="Vlasnik IMMMUNO NATURA brenda za BiH: “GREENWELLS d.o.o.“, sjedište: Sarajevo, Ulica M. Tita 11., e-mail: info@immuno-natura.ba, tel.: +38761787346"
    />

    <section
      id="_heroPage"
      className="hero is-large"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body">
        <div className="container">
          <div className="title">IMPRESUM</div>
        </div>
      </div>
    </section>

    <span className="_line" />

    <section id="_pageContent_other" className="section _impresum">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="columns">
              <div className="column _headline">
                <h2 className="subtitle is-4 is-spaced">Immuno natura doo</h2>
                <div className="content">
                  <p>
                    Proizvodi pod brendom IMMUNO NATURA su proizvodi firme EURO
                    TRIJUMF , <br />
                    Firma EURO TRIJUMF je osnovana 2015 godine i osnovana je
                    prvenstveno kao frima koja se bavi veleprodajom namirnica.
                  </p>
                  <p>
                    U cilju prilagođavanja poslovanja dinamici i razvoju
                    trži&scaron;ta EURO TRIJUMF je 2021. godine otvorio novi
                    sektor poslovanja &ldquo;IMMUNO NATURA&rdquo; čije
                    teži&scaron;te rada leži prvenstveno na proizvodnji i
                    distribuciji zdrave hrane i dodataka ishrani. Saradnici iz
                    sektora &ldquo;IMMUNO NATURA&rdquo; su vrsni poznavaoci
                    modernih tokova zdrave ishrane i moderne prehrambene
                    tehnologije &scaron;to ovom sektoru omogućava pozicioniranje
                    na trži&scaron;tu visoko-kvalitativnih produkata sa
                    ljekovitim svojstvima odnosno produkata iz kategorije
                    dodataka ishrani.
                  </p>
                  <p>
                    U samom početku se &ldquo;IMMUNO NATURA&rdquo; fokusirala se
                    na hladno cijeđena, djevičanska ulja sa enormnim ljekovitim
                    potencijalom &ndash; čorokotovo ulje, laneno ulje, bundevino
                    ulje, kokosovo ulje i konopljino ulje,- kao i na sjemenke iz
                    kojih se cijede ovi proizvodi (cijele ili mljevene).
                    &ldquo;IMMUNO NATURA&rdquo; uskoro planira uvesti u svoj
                    asortiman i druga ulja sa &scaron;irokom paletom ljekovitih
                    supstanci.
                  </p>
                </div>
                <p className="title is-6">ADRESA</p>
                <p className="subtitle is-spaced">
                  Ul. Maršala Tita bb <br /> Srebrenik, Bosna i Hercegovina
                </p>
                <p className="title is-6">EMAIL</p>
                <p className="subtitle is-spaced">
                  <a href="mailto:info@immuno-natura.ba">
                    info@immuno-natura.ba
                  </a>
                </p>
                <p className="title is-6">TELEFON</p>
                <p className="subtitle is-spaced">
                  <a href="tel:+387 62 179 777">+387 62 179 777</a> (Viber /
                  WhatsApp)
                </p>
                <p className="title is-6">REGISTRACIJSKI BROJ KOMPANIJE</p>
                <p className="subtitle is-spaced">ID 4210261820004</p>
                <p className="title is-6">
                  REGISTROVAN JE U POREZNOJ UPRAVI FBIH
                </p>
                <p className="subtitle is-spaced">
                  Kantonalni porezni ured TUZLA
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default Impresum
